body {
    margin: 0;
    font-family: 'Open Sans', Helvetica, Arial, Verdana, 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center !important;
}
.button {
    display: block;
    background-color: #F8BE15;
    font-weight: bold;
   box-shadow: none

}
.booking-box{
    text-align: center;
    font-weight: bold;
}
